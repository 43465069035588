site.endeca.result.product = jQuery.extend( true, {}, generic.endeca.result.product, {
    formatPriceRange: function() {
        var minPrice = this.resultData.skus[0];
        var maxPrice = this.resultData.skus[0];
        for(var i = 0; i < this.resultData.skus.length; i++){
            var currSku = this.resultData.skus[i];
            minPrice = (currSku.PRICE < minPrice.PRICE) ? currSku : minPrice;
            maxPrice = (currSku.PRICE > maxPrice.PRICE) ? currSku : maxPrice;
        }
        
        return ( minPrice !== maxPrice ) ? minPrice.formattedTaxedPrice + ' - ' + maxPrice.formattedTaxedPrice : this.resultData.skus[0].formattedTaxedPrice;
    },
    setupShades: function() {

        var skus = this.resultData.skus;

        for ( var i = 0; i < skus.length; i++ ) {
            skus[i].PRODUCT_ID = this.resultData.PRODUCT_ID;
            skus[i].url = this.resultData.url;
        }

        var shadesNode = this.node.find('.shades');
        var shadesContainerNode = this.node.find('.shade-select-container');
        var shadesListNode = this.node.find('.shade-select');
        var priceNode = this.node.find('.size-price');
        var imageNode = this.node.find('.sku-image');

        if ( shadesNode.length ) {
            shadesNode.addClass( 'shades_' + this.resultData.skus.length );
            $(skus).each(function(index, sku) {
              var shadeHex = sku.HEX_VALUE_STRING;
              // explode
              var shadeHex = shadeHex.split(',');
              if(shadeHex.length == 1) {
                sku['SWATCH_TYPE'] = 'single';
                sku['HEX_VALUE_1'] = shadeHex[0];
              }else if(shadeHex.length == 2){
                sku['SWATCH_TYPE'] = 'duo';
                sku['HEX_VALUE_1'] = shadeHex[0];
                sku['HEX_VALUE_2'] = shadeHex[1];
              }else if(shadeHex.length == 3){
                sku['SWATCH_TYPE'] = 'trio';
                sku['HEX_VALUE_1'] = shadeHex[0];
                sku['HEX_VALUE_2'] = shadeHex[1];
                sku['HEX_VALUE_3'] = shadeHex[2];
              }else if(shadeHex.length == 5){
                sku['SWATCH_TYPE'] = 'quint';
                sku['HEX_VALUE_1'] = shadeHex[0];
                sku['HEX_VALUE_2'] = shadeHex[1];
                sku['HEX_VALUE_3'] = shadeHex[2];
                sku['HEX_VALUE_4'] = shadeHex[3];
                sku['HEX_VALUE_5'] = shadeHex[4];
              }
              //misc flag for skus
              var skuMiscFlag =  sku.MISC_FLAG;
              if(skuMiscFlag == 1){
                sku['MISC_FLAG_TEXT'] = '- ' + rb.endeca.misc_flag_new;
              }else if(skuMiscFlag == 2){
                sku['MISC_FLAG_TEXT'] = '- ' + rb.endeca.misc_flag_limited_edition;
              }else if(skuMiscFlag == 3){
                sku['MISC_FLAG_TEXT'] = '- ' + rb.endeca.misc_flag_new_shades;
              }else if(skuMiscFlag == 94 || skuMiscFlag == 5){
                sku['MISC_FLAG_TEXT'] = '- ' + rb.endeca.misc_flag_online_exclusive;
              }else{
                sku['MISC_FLAG_TEXT'] = '';
              }
            });

            this.shadeResults = new site.endeca.results({
                resultData: skus,
                parentNode: shadesNode,
                childClass: 'shade',
                configuration: this.configuration,
                mixins: this.mixins
            });
            this.shadeResults.displayResults();
            this.shadeResults.show();
        }

        if ( shadesListNode.length > 0 ) {
            shadesListNode.addClass( 'shades_' + this.resultData.skus.length );

            this.shadeListResults = new site.endeca.results({
                resultData: skus,
                parentNode: shadesListNode,
                childClass: 'shade',
                configuration: jQuery.extend( {
                    shadeTemplatePath: '/templates/endeca/products/shade-select.tmpl'
                }, this.configuration ),
                mixins: this.mixins
            });
            this.shadeListResults.displayResults();
            //this.shadeListResults.show();
        }

        var that = this;

        this.node.bind( 'sku:select', function( event, sku ) {
            // that.setupAddToBag( sku.SKU_BASE_ID );
            if ( priceNode.length ) {
                var formattedPrice = (_.isUndefined(sku.formattedTaxedPrice)) ? '' : sku.formattedTaxedPrice;
                var productSize = (_.isUndefined(sku.PRODUCT_SIZE)) ? '' : sku.PRODUCT_SIZE;
                priceNode.html( '<span class="text--bold">' + formattedPrice + '</span> ' + productSize );

            }
            if ( imageNode.length ) {
                imageNode.attr( 'src', sku.XS_IMAGE );
            }
        });
        
        if ( site && site.ShadePicker ) {
            this.shadePricker = new site.ShadePicker( this.resultData );
            shadesContainerNode.show();
        }
    },
});
